.firebaseui-container {
    background: rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    min-height: 150px;
    padding-top: 10px;
    border-radius: 20px;
    box-shadow: none;
  }

  #firebaseui-container div {
      box-shadow: none;
  }

  .firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
      background-color: darkgreen;
      border-radius: 10px;
  }
